import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
            <div>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img className="align-top" src={"img/new.png"} alt="..."id="logo-img" />
          </a></div>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li> */}
            {/*   <a href="#features" className="page-scroll"> */}
            {/*     Features */}
            {/*   </a> */}
            {/* </li> */}
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#solutions" className="page-scroll">
                  Solutions
              </a>
            </li>
            {/* <li> */}
            {/*   <a href="#portfolio" className="page-scroll"> */}
            {/*     Gallery */}
            {/*   </a> */}
            {/* </li> */}
            {/* <li> */}
            {/*   <a href="#testimonials" className="page-scroll"> */}
            {/*     Testimonials */}
            {/*   </a> */}
            {/* </li> */}
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
